import React from "react"

const MediatorLogo = ({ className }) => (
  <svg
    id="header-logo"
    className={className}
    viewBox="0 0 300 300"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path
      d="M234.962 174.273c39.302-50.584 9.77-126.085-3.902-136.707-13.672-10.623-79.873-8.237-119.176 42.347 28.618-18.326 73.906-16.068 89.403-4.028 15.496 12.041 44.36 66.13 33.675 98.388z"
      fill="#54ad9d"
    />
    <clipPath id="a">
      <path d="M234.962 174.273c39.302-50.584 9.77-126.085-3.902-136.707-13.672-10.623-79.873-8.237-119.176 42.347 28.618-18.326 73.906-16.068 89.403-4.028 15.496 12.041 44.36 66.13 33.675 98.388z" />
    </clipPath>
    <g clipPath="url(#a)">
      <path
        d="M234.962 174.273c21.491-39.178-3.856-102.969-20.231-115.692-16.375-12.722-70.195-9.174-102.847 21.332 28.618-18.326 73.443-16.428 89.403-4.028 15.959 12.4 44.36 66.13 33.675 98.388z"
        fill="#216d5f"
      />
    </g>
    <path
      d="M253.391 8.824c6.416 4.985 7.578 14.242 2.593 20.658-4.985 6.417-14.242 7.579-20.659 2.593-6.416-4.985-7.578-14.242-2.593-20.658 4.986-6.417 14.243-7.579 20.659-2.593z"
      fill="#54ad9d"
    />
    <g>
      <path
        d="M157.508 85.618C94.049 76.874 43.43 140.2 41.067 157.352c-2.364 17.151 32.803 73.29 96.261 82.034-30.179-15.62-50.868-55.97-48.189-75.41 2.679-19.441 35.089-71.482 68.369-78.358z"
        fill="gray"
      />
      <clipPath id="b">
        <path d="M157.508 85.618C94.049 76.874 43.43 140.2 41.067 157.352c-2.364 17.151 32.803 73.29 96.261 82.034-30.179-15.62-50.868-55.97-48.189-75.41 2.679-19.441 35.089-71.482 68.369-78.358z" />
      </clipPath>
      <g clipPath="url(#b)">
        <path
          d="M157.508 85.618c-44.675.978-87.246 54.824-90.077 75.367-2.831 20.542 27.152 65.377 69.897 78.401-30.179-15.62-50.948-55.389-48.189-75.41 2.759-20.021 35.089-71.482 68.369-78.358z"
          fill="#404040"
        />
      </g>
      <path
        d="M5.01 152.383c1.109-8.05 8.545-13.684 16.594-12.575 8.05 1.109 13.684 8.545 12.575 16.594-1.109 8.05-8.545 13.685-16.594 12.575-8.05-1.109-13.684-8.545-12.575-16.594z"
        fill="gray"
      />
    </g>
    <g>
      <path
        d="M121.138 196.497c24.156 59.329 104.308 71.503 120.343 64.974 16.036-6.529 47.07-65.054 22.914-124.382 1.562 33.946-23.038 72.038-41.213 79.438-18.176 7.4-79.45 5.353-102.044-20.03z"
        fill="gray"
      />
      <clipPath id="c">
        <path d="M121.138 196.497c24.156 59.329 104.308 71.503 120.343 64.974 16.036-6.529 47.07-65.054 22.914-124.382 1.562 33.946-23.038 72.038-41.213 79.438-18.176 7.4-79.45 5.353-102.044-20.03z" />
      </clipPath>
      <g clipPath="url(#c)">
        <path
          d="M121.138 196.497c23.184 38.201 91.102 48.145 110.307 40.325 19.206-7.819 43.043-56.203 32.95-99.733 1.562 33.946-22.495 71.817-41.213 79.438-18.719 7.621-79.45 5.353-102.044-20.03z"
          fill="#404040"
        />
      </g>
      <path
        d="M255.207 295.182c-7.526 3.064-16.124-.559-19.188-8.084-3.064-7.526.558-16.123 8.084-19.188 7.526-3.064 16.123.558 19.187 8.084s-.558 16.123-8.083 19.188z"
        fill="gray"
      />
    </g>
  </svg>
)

export default MediatorLogo
